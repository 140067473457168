var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo}}),_c('PageHeader',{attrs:{"title":_vm.isEditing ? ("" + _vm.titleEdit) : _vm.titleNew}}),_c('v-card',[_c('div',{staticClass:"row"},[_c('v-container',{staticClass:"pt-0"},[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","clearable":"","label":"Nombre del formato","dense":"","outlined":"","rules":_vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.nombre),
                      _vm.rules.maxLength(_vm.nombre, 100) ])},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"label":"Servidor","items":_vm.servidores,"item-text":"value","item-value":"id","dense":"","outlined":"","clearable":"","rules":_vm.rules.required},model:{value:(_vm.servidor),callback:function ($$v) {_vm.servidor=$$v},expression:"servidor"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.entidadesFacturantes,"label":"Entidad facturante","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.entidadFacturanteSelected),callback:function ($$v) {_vm.entidadFacturanteSelected=$$v},expression:"entidadFacturanteSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Nombre remitente","dense":"","outlined":"","rules":_vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.nombreRemitente),
                      _vm.rules.maxLength(_vm.nombreRemitente, 100) ])},model:{value:(_vm.nombreRemitente),callback:function ($$v) {_vm.nombreRemitente=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreRemitente"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Email remitente","dense":"","outlined":"","rules":_vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.emailRemitente),
                      _vm.rules.maxLength(_vm.emailRemitente, 100),
                      _vm.rules.validateEmailFormat(_vm.emailRemitente) ])},model:{value:(_vm.emailRemitente),callback:function ($$v) {_vm.emailRemitente=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"emailRemitente"}})],1),_c('v-col',{staticClass:"d-flex py-0 justify-content-center",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-checkbox',{staticClass:"mt-1",attrs:{"id":"switch1"},model:{value:(_vm.activo),callback:function ($$v) {_vm.activo=$$v},expression:"activo"}}),_c('label',{staticClass:"mt-2",attrs:{"for":"switch1"}},[_vm._v("Activo")]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"col-12 py-0"},[_c('v-col',{staticClass:"pa-0 py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Asunto","dense":"","outlined":"","rules":_vm.rules.required.concat([
                    _vm.rules.requiredTrim(_vm.asunto),
                    _vm.rules.maxLength(_vm.asunto, 150) ])},model:{value:(_vm.asunto),callback:function ($$v) {_vm.asunto=$$v},expression:"asunto"}})],1),_c('TextEditor',{ref:"text",attrs:{"value":_vm.content,"changed":_vm.contentChanged},on:{"update:value":function($event){_vm.content=$event},"update:changed":function($event){_vm.contentChanged=$event}}})],1),_c('v-container',[_c('v-row',{staticClass:"pr-4 pl-4 pt-4",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"loading":_vm.loadingSaveBtn},on:{"click":_vm.saveContent}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }